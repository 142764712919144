import axieParts from "../../../const/parts.json";
import axieExtras from "../../../const/axie.json";

class UserStore {
    constructor(Vue, options) {
        // Check for existing token in localstorage
        let localToken = localStorage.getItem('user-jwt') || '';

        // Lets make a new instance to store the data
        this.storeVM = new Vue({
            data() {
                return {
                    token: localToken === '' ? null : localToken,
                    searchHistory: [],
                    recentListings: [],
                    listIndex: 0,
                    lastListingReceivedTime: 0,
                };
            },
        });
    }

    get is_authenticated() {
        return this.storeVM.$data.token != null;
    }

    get info() {
        if (this.storeVM.$data.token != null) {
            return JSON.parse(atob(this.storeVM.$data.token.split('.')[1]));
        }
        return null;
    }

    get token() {
        return this.storeVM.$data.token;
    }

    set token(t) {
        this.storeVM.$data.token = t;
    }

    addSearch (s) {
        this.storeVM.$data.searchHistory = this.storeVM.$data.searchHistory.filter(a => JSON.stringify(a.search) != JSON.stringify(s.search));
        this.storeVM.$data.searchHistory.unshift(s);
        this.storeVM.$data.listIndex = 0;
    }

    deleteSearch (s) {
        this.storeVM.$data.listIndex = 0;
        this.storeVM.$data.searchHistory = this.storeVM.$data.searchHistory.filter(a => JSON.stringify(a.search) != JSON.stringify(s));
    }

    get searchHistory() {
        return this.storeVM.$data.searchHistory;
    }

    get listIndex() {
        return this.storeVM.$data.listIndex;
    }

    set listIndex(i) {
        this.storeVM.$data.listIndex = i;
    }

    get activeItem () {
        if (this.storeVM.$data.searchHistory.length > this.listIndex) {
            return this.storeVM.$data.searchHistory[this.storeVM.$data.listIndex];
        } else {
            return null;
        }
    }

    get recentListings () {
        return this.storeVM.$data.recentListings;
    }

    get lastListingReceivedTime () {
        return this.storeVM.$data.lastListingReceivedTime;
    }

    checkNewListings (data) {
        this.storeVM.$data.lastListingReceivedTime = new Date().getTime();

        // Each new listing
        for (let axie of data) {
            // Each saved search
            for (let s of this.storeVM.$data.searchHistory) {
                if (s.search.checkNew != true) continue;

                let match = checkSingleFilter(axie, s.search);
                if (match) {
                    console.log("Got a match");
                    console.log(axie);
                    this.storeVM.$data.recentListings.unshift({
                        search: JSON.parse(JSON.stringify(s.search)),
                        match: axie
                    });
                }
            }
        }
    }

    logout () {
        // Clear the store
        this.storeVM.$data.token = null;
        this.storeVM.$data.searchHistory = [];
        this.storeVM.$data.recentListings = [];
        this.storeVM.$data.listIndex = 0;
        this.storeVM.$data.lastListingReceivedTime = 0;
    }

}

function checkSingleFilter(axie, search) {
    // Pureness
    if (search.pureness != null && search.pureness != 0 && axie.pureness != search.pureness) return false;

    // Class
    if (search.class != null && axie.class != search.class) return false;

    // Breedcount
    if (axie.breedCount < search.breedCount[0] || axie.breedCount > search.breedCount[1]) return false;

    // Purity
    if (search.purity != null && axie.purity < search.purity) return false;

    // Speed
    if (axie.stats.speed < search.speed[0] || axie.stats.speed > search.speed[1]) return false;

    // Rsyn
    if (search.rsyn != null && search.rsyn != 0 && axie.rsyn < search.rsyn) return false;

    // Check parts
    for (let p of axieExtras.allParts) {
        let validD = search.parts.d.filter(x => x.type == p).map(x => x.partId);
        if (validD.length > 0) {
            if (!validD.includes(axie.parsedGenes[p].d.partId)) return false;
        }

        let validR1 = search.parts.r1.filter(x => x.type == p).map(x => x.partId);
        if (validR1.length > 0) {
            if (!validR1.includes(axie.parsedGenes[p].r1.partId)) return false;
        }

        let validR2 = search.parts.r2.filter(x => x.type == p).map(x => x.partId);
        if (validR2.length > 0) {
            if (!validR2.includes(axie.parsedGenes[p].r2.partId)) return false;
        }

    }

    return true;

}


export default {
    /**
     * Install plugin
     * @param {Vue} Vue - Vue instance
     * @param {Object} options - Options for the plugin
     */
    install: (Vue, options = {}) => {
        let d = new UserStore(Vue, options);
        let constData = {
            parts: axieParts,
            axie: axieExtras
        };
        Vue.user = d;
        Vue.prototype.$user = d;

        Vue.const = constData;
        Vue.prototype.$const = constData;
    },
};

// Usage: import UserStore from 'plugins/store'; Vue.use(UserStore);

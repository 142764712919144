<template lang="pug">
#mainblock
  p(v-if="$user.activeItem == null || !$user.activeItem.results") NO ACTIVE SEARCH
  div(v-else)
    p {{`TOTAL RESULTS ${$user.activeItem.results.total}, FILTERED ${$user.activeItem.results.axies.length}`}}
    div(v-for="a in $user.activeItem.results.axies")
      b-tooltip(type="is-dark")
        a(:href="a.link" target="_blank") {{`${a.price.toFixed(4)} (${a.breedCount} BC) - ${a.id}`}}
        <template v-slot:content>
          .genegrid
            p(v-for="p in parseGeneList(a.parsedGenes)" :style="{ color: p.colour}") {{p.name}}

        </template>
</template>

<script>
export default {
    name: "Results",
    data () {
        return {
            ip_address: "N/A",
            server_response: "N/A"
        }
    },
    methods: {
        parseGeneList: function (p) {
            let r = [];
            const genes = ['d', 'r1', 'r2'];
            for (let i of this.$const.axie.allParts) {
                for (let g of genes) {
                    r.push({
                        name: p[i][g].name,
                        colour: this.$const.axie.colours[p[i][g].cls]
                    });
                }
            }
            return r;
        }
    }
}
</script>

<style scoped>
.genegrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    column-gap: 5px;
}
</style>

module.exports = {
    "back-anemone": {
        "class": "aquatic",
        "name": "Anemone (back)",
        "partId": "back-anemone",
        "specialGenes": "",
        "type": "back"
    },
    "back-balloon": {
        "class": "bird",
        "name": "Balloon",
        "partId": "back-balloon",
        "specialGenes": "",
        "type": "back"
    },
    "back-bidens": {
        "class": "plant",
        "name": "Bidens",
        "partId": "back-bidens",
        "specialGenes": "",
        "type": "back"
    },
    "back-blue-moon": {
        "class": "aquatic",
        "name": "Blue Moon",
        "partId": "back-blue-moon",
        "specialGenes": "",
        "type": "back"
    },
    "back-bone-sail": {
        "class": "reptile",
        "name": "Bone Sail",
        "partId": "back-bone-sail",
        "specialGenes": "",
        "type": "back"
    },
    "back-buzz-buzz": {
        "class": "bug",
        "name": "Buzz Buzz",
        "partId": "back-buzz-buzz",
        "specialGenes": "",
        "type": "back"
    },
    "back-candy-canes": {
        "class": "bug",
        "name": "Candy Canes",
        "partId": "back-candy-canes",
        "specialGenes": "xmas",
        "type": "back"
    },
    "back-croc": {
        "class": "reptile",
        "name": "Croc",
        "partId": "back-croc",
        "specialGenes": "",
        "type": "back"
    },
    "back-crystal-hermit": {
        "class": "aquatic",
        "name": "Crystal Hermit",
        "partId": "back-crystal-hermit",
        "specialGenes": "mystic",
        "type": "back"
    },
    "back-cupid": {
        "class": "bird",
        "name": "Cupid",
        "partId": "back-cupid",
        "specialGenes": "",
        "type": "back"
    },
    "back-furball": {
        "class": "beast",
        "name": "Furball",
        "partId": "back-furball",
        "specialGenes": "",
        "type": "back"
    },
    "back-garish-worm": {
        "class": "bug",
        "name": "Garish Worm",
        "partId": "back-garish-worm",
        "specialGenes": "",
        "type": "back"
    },
    "back-goldfish": {
        "class": "aquatic",
        "name": "Goldfish",
        "partId": "back-goldfish",
        "specialGenes": "",
        "type": "back"
    },
    "back-green-thorns": {
        "class": "reptile",
        "name": "Green Thorns",
        "partId": "back-green-thorns",
        "specialGenes": "",
        "type": "back"
    },
    "back-hamaya": {
        "class": "beast",
        "name": "Hamaya",
        "partId": "back-hamaya",
        "specialGenes": "japan",
        "type": "back"
    },
    "back-hasagi": {
        "class": "beast",
        "name": "Hasagi",
        "partId": "back-hasagi",
        "specialGenes": "mystic",
        "type": "back"
    },
    "back-hermit": {
        "class": "aquatic",
        "name": "Hermit",
        "partId": "back-hermit",
        "specialGenes": "",
        "type": "back"
    },
    "back-hero": {
        "class": "beast",
        "name": "Hero",
        "partId": "back-hero",
        "specialGenes": "",
        "type": "back"
    },
    "back-indian-star": {
        "class": "reptile",
        "name": "Indian Star",
        "partId": "back-indian-star",
        "specialGenes": "",
        "type": "back"
    },
    "back-jaguar": {
        "class": "beast",
        "name": "Jaguar",
        "partId": "back-jaguar",
        "specialGenes": "",
        "type": "back"
    },
    "back-kingfisher": {
        "class": "bird",
        "name": "Kingfisher",
        "partId": "back-kingfisher",
        "specialGenes": "",
        "type": "back"
    },
    "back-mint": {
        "class": "plant",
        "name": "Mint",
        "partId": "back-mint",
        "specialGenes": "",
        "type": "back"
    },
    "back-origami": {
        "class": "bird",
        "name": "Origami",
        "partId": "back-origami",
        "specialGenes": "japan",
        "type": "back"
    },
    "back-perch": {
        "class": "aquatic",
        "name": "Perch",
        "partId": "back-perch",
        "specialGenes": "",
        "type": "back"
    },
    "back-pigeon-post": {
        "class": "bird",
        "name": "Pigeon Post",
        "partId": "back-pigeon-post",
        "specialGenes": "",
        "type": "back"
    },
    "back-pink-turnip": {
        "class": "plant",
        "name": "Pink Turnip",
        "partId": "back-pink-turnip",
        "specialGenes": "mystic",
        "type": "back"
    },
    "back-pumpkin": {
        "class": "plant",
        "name": "Pumpkin",
        "partId": "back-pumpkin",
        "specialGenes": "",
        "type": "back"
    },
    "back-raven": {
        "class": "bird",
        "name": "Raven",
        "partId": "back-raven",
        "specialGenes": "",
        "type": "back"
    },
    "back-red-ear": {
        "class": "reptile",
        "name": "Red Ear",
        "partId": "back-red-ear",
        "specialGenes": "",
        "type": "back"
    },
    "back-risky-beast": {
        "class": "beast",
        "name": "Risky Beast",
        "partId": "back-risky-beast",
        "specialGenes": "",
        "type": "back"
    },
    "back-ronin": {
        "class": "beast",
        "name": "Ronin",
        "partId": "back-ronin",
        "specialGenes": "",
        "type": "back"
    },
    "back-rugged-sail": {
        "class": "reptile",
        "name": "Rugged Sail",
        "partId": "back-rugged-sail",
        "specialGenes": "mystic",
        "type": "back"
    },
    "back-sandal": {
        "class": "bug",
        "name": "Sandal",
        "partId": "back-sandal",
        "specialGenes": "",
        "type": "back"
    },
    "back-scarab": {
        "class": "bug",
        "name": "Scarab",
        "partId": "back-scarab",
        "specialGenes": "",
        "type": "back"
    },
    "back-shiitake": {
        "class": "plant",
        "name": "Shiitake",
        "partId": "back-shiitake",
        "specialGenes": "",
        "type": "back"
    },
    "back-1nd14n-5t4r": {
        "class": "reptile",
        "name": "1ND14N-5T4R",
        "partId": "back-1nd14n-5t4r",
        "specialGenes": "bionic",
        "type": "back"
    },
    "back-snail-shell": {
        "class": "bug",
        "name": "Snail Shell",
        "partId": "back-snail-shell",
        "specialGenes": "",
        "type": "back"
    },
    "back-spiky-wing": {
        "class": "bug",
        "name": "Spiky Wing",
        "partId": "back-spiky-wing",
        "specialGenes": "",
        "type": "back"
    },
    "back-sponge": {
        "class": "aquatic",
        "name": "Sponge",
        "partId": "back-sponge",
        "specialGenes": "",
        "type": "back"
    },
    "back-starry-balloon": {
        "class": "bird",
        "name": "Starry Balloon",
        "partId": "back-starry-balloon",
        "specialGenes": "mystic",
        "type": "back"
    },
    "back-starry-shell": {
        "class": "bug",
        "name": "Starry Shell",
        "partId": "back-starry-shell",
        "specialGenes": "mystic",
        "type": "back"
    },
    "back-timber": {
        "class": "beast",
        "name": "Timber",
        "partId": "back-timber",
        "specialGenes": "",
        "type": "back"
    },
    "back-tri-feather": {
        "class": "bird",
        "name": "Tri Feather",
        "partId": "back-tri-feather",
        "specialGenes": "",
        "type": "back"
    },
    "back-tri-spikes": {
        "class": "reptile",
        "name": "Tri Spikes",
        "partId": "back-tri-spikes",
        "specialGenes": "",
        "type": "back"
    },
    "back-turnip": {
        "class": "plant",
        "name": "Turnip",
        "partId": "back-turnip",
        "specialGenes": "",
        "type": "back"
    },
    "back-watering-can": {
        "class": "plant",
        "name": "Watering Can",
        "partId": "back-watering-can",
        "specialGenes": "",
        "type": "back"
    },
    "back-yakitori": {
        "class": "plant",
        "name": "Yakitori",
        "partId": "back-yakitori",
        "specialGenes": "japan",
        "type": "back"
    },
    "ears-beetle-spike": {
        "class": "bug",
        "name": "Beetle Spike",
        "partId": "ears-beetle-spike",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-belieber": {
        "class": "beast",
        "name": "Belieber",
        "partId": "ears-belieber",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-bubblemaker": {
        "class": "aquatic",
        "name": "Bubblemaker",
        "partId": "ears-bubblemaker",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-clover": {
        "class": "plant",
        "name": "Clover",
        "partId": "ears-clover",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-curly": {
        "class": "bird",
        "name": "Curly",
        "partId": "ears-curly",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-curved-spine": {
        "class": "reptile",
        "name": "Curved Spine",
        "partId": "ears-curved-spine",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-deadly-pogona": {
        "class": "reptile",
        "name": "Deadly Pogona",
        "partId": "ears-deadly-pogona",
        "specialGenes": "mystic",
        "type": "ears"
    },
    "ears-ear-breathing": {
        "class": "bug",
        "name": "Ear Breathing",
        "partId": "ears-ear-breathing",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-early-bird": {
        "class": "bird",
        "name": "Early Bird",
        "partId": "ears-early-bird",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-earwing": {
        "class": "bug",
        "name": "Earwing",
        "partId": "ears-earwing",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-friezard": {
        "class": "reptile",
        "name": "Friezard",
        "partId": "ears-friezard",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-gill": {
        "class": "aquatic",
        "name": "Gill",
        "partId": "ears-gill",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-heart-cheek": {
        "class": "bird",
        "name": "Heart Cheek",
        "partId": "ears-heart-cheek",
        "specialGenes": "mystic",
        "type": "ears"
    },
    "ears-hollow": {
        "class": "plant",
        "name": "Hollow",
        "partId": "ears-hollow",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-inkling": {
        "class": "aquatic",
        "name": "Inkling",
        "partId": "ears-inkling",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-innocent-lamb": {
        "class": "beast",
        "name": "Innocent Lamb",
        "partId": "ears-innocent-lamb",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-karimata": {
        "class": "bird",
        "name": "Karimata",
        "partId": "ears-karimata",
        "specialGenes": "japan",
        "type": "ears"
    },
    "ears-larva": {
        "class": "bug",
        "name": "Larva",
        "partId": "ears-larva",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-leaf-bug": {
        "class": "bug",
        "name": "Leaf Bug",
        "partId": "ears-leaf-bug",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-leafy": {
        "class": "plant",
        "name": "Leafy",
        "partId": "ears-leafy",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-lotus": {
        "class": "plant",
        "name": "Lotus",
        "partId": "ears-lotus",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-maiko": {
        "class": "plant",
        "name": "Maiko",
        "partId": "ears-maiko",
        "specialGenes": "japan",
        "type": "ears"
    },
    "ears-merry-lamb": {
        "class": "beast",
        "name": "Merry Lamb",
        "partId": "ears-merry-lamb",
        "specialGenes": "xmas",
        "type": "ears"
    },
    "ears-mon": {
        "class": "bug",
        "name": "Mon",
        "partId": "ears-mon",
        "specialGenes": "japan",
        "type": "ears"
    },
    "ears-nimo": {
        "class": "aquatic",
        "name": "Nimo",
        "partId": "ears-nimo",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-nut-cracker": {
        "class": "beast",
        "name": "Nut Cracker",
        "partId": "ears-nut-cracker",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-nyan": {
        "class": "beast",
        "name": "Nyan",
        "partId": "ears-nyan",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-owl": {
        "class": "bird",
        "name": "Owl",
        "partId": "ears-owl",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-peace-maker": {
        "class": "bird",
        "name": "Peace Maker",
        "partId": "ears-peace-maker",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-pink-cheek": {
        "class": "bird",
        "name": "Pink Cheek",
        "partId": "ears-pink-cheek",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-pogona": {
        "class": "reptile",
        "name": "Pogona",
        "partId": "ears-pogona",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-pointy-nyan": {
        "class": "beast",
        "name": "Pointy Nyan",
        "partId": "ears-pointy-nyan",
        "specialGenes": "mystic",
        "type": "ears"
    },
    "ears-puppy": {
        "class": "beast",
        "name": "Puppy",
        "partId": "ears-puppy",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-red-nimo": {
        "class": "aquatic",
        "name": "Red Nimo",
        "partId": "ears-red-nimo",
        "specialGenes": "mystic",
        "type": "ears"
    },
    "ears-risky-bird": {
        "class": "bird",
        "name": "Risky Bird",
        "partId": "ears-risky-bird",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-rosa": {
        "class": "plant",
        "name": "Rosa",
        "partId": "ears-rosa",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-sakura": {
        "class": "plant",
        "name": "Sakura",
        "partId": "ears-sakura",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-seaslug": {
        "class": "aquatic",
        "name": "Seaslug",
        "partId": "ears-seaslug",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-sidebarb": {
        "class": "reptile",
        "name": "Sidebarb",
        "partId": "ears-sidebarb",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-small-frill": {
        "class": "reptile",
        "name": "Small Frill",
        "partId": "ears-small-frill",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-swirl": {
        "class": "reptile",
        "name": "Swirl",
        "partId": "ears-swirl",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-tassels": {
        "class": "bug",
        "name": "Tassels",
        "partId": "ears-tassels",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-the-last-leaf": {
        "class": "plant",
        "name": "The Last Leaf",
        "partId": "ears-the-last-leaf",
        "specialGenes": "mystic",
        "type": "ears"
    },
    "ears-tiny-fan": {
        "class": "aquatic",
        "name": "Tiny Fan",
        "partId": "ears-tiny-fan",
        "specialGenes": "",
        "type": "ears"
    },
    "ears-vector": {
        "class": "bug",
        "name": "Vector",
        "partId": "ears-vector",
        "specialGenes": "mystic",
        "type": "ears"
    },
    "ears-zen": {
        "class": "beast",
        "name": "Zen",
        "partId": "ears-zen",
        "specialGenes": "",
        "type": "ears"
    },
    "eyes-blossom": {
        "class": "plant",
        "name": "Blossom",
        "partId": "eyes-blossom",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-bookworm": {
        "class": "bug",
        "name": "Bookworm",
        "partId": "eyes-bookworm",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-broken-bookworm": {
        "class": "bug",
        "name": "Broken Bookworm",
        "partId": "eyes-broken-bookworm",
        "specialGenes": "mystic",
        "type": "eyes"
    },
    "eyes-calico-zeal": {
        "class": "beast",
        "name": "Calico Zeal",
        "partId": "eyes-calico-zeal",
        "specialGenes": "mystic",
        "type": "eyes"
    },
    "eyes-chubby": {
        "class": "beast",
        "name": "Chubby",
        "partId": "eyes-chubby",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-clear": {
        "class": "aquatic",
        "name": "Clear",
        "partId": "eyes-clear",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-confused": {
        "class": "plant",
        "name": "Confused",
        "partId": "eyes-confused",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-crimson-gecko": {
        "class": "reptile",
        "name": "Crimson Gecko",
        "partId": "eyes-crimson-gecko",
        "specialGenes": "mystic",
        "type": "eyes"
    },
    "eyes-cucumber-slice": {
        "class": "plant",
        "name": "Cucumber Slice",
        "partId": "eyes-cucumber-slice",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-dokuganryu": {
        "class": "reptile",
        "name": "Dokuganryu",
        "partId": "eyes-dokuganryu",
        "specialGenes": "japan",
        "type": "eyes"
    },
    "eyes-dreamy-papi": {
        "class": "plant",
        "name": "Dreamy Papi",
        "partId": "eyes-dreamy-papi",
        "specialGenes": "mystic",
        "type": "eyes"
    },
    "eyes-gecko": {
        "class": "reptile",
        "name": "Gecko",
        "partId": "eyes-gecko",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-gero": {
        "class": "aquatic",
        "name": "Gero",
        "partId": "eyes-gero",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-insomnia": {
        "class": "aquatic",
        "name": "Insomnia",
        "partId": "eyes-insomnia",
        "specialGenes": "mystic",
        "type": "eyes"
    },
    "eyes-kabuki": {
        "class": "reptile",
        "name": "Kabuki",
        "partId": "eyes-kabuki",
        "specialGenes": "japan",
        "type": "eyes"
    },
    "eyes-kotaro?": {
        "class": "bug",
        "name": "Kotaro?",
        "partId": "eyes-kotaro?",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-little-owl": {
        "class": "bird",
        "name": "Little Owl",
        "partId": "eyes-little-owl",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-little-peas": {
        "class": "beast",
        "name": "Little Peas",
        "partId": "eyes-little-peas",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-lucas": {
        "class": "bird",
        "name": "Lucas",
        "partId": "eyes-lucas",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-mavis": {
        "class": "bird",
        "name": "Mavis",
        "partId": "eyes-mavis",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-neo": {
        "class": "bug",
        "name": "Neo",
        "partId": "eyes-neo",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-nerdy": {
        "class": "bug",
        "name": "Nerdy",
        "partId": "eyes-nerdy",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-papi": {
        "class": "plant",
        "name": "Papi",
        "partId": "eyes-papi",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-puppy": {
        "class": "beast",
        "name": "Puppy",
        "partId": "eyes-puppy",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-robin": {
        "class": "bird",
        "name": "Robin",
        "partId": "eyes-robin",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-scar": {
        "class": "reptile",
        "name": "Scar",
        "partId": "eyes-scar",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-sky-mavis": {
        "class": "bird",
        "name": "Sky Mavis",
        "partId": "eyes-sky-mavis",
        "specialGenes": "mystic",
        "type": "eyes"
    },
    "eyes-sleepless": {
        "class": "aquatic",
        "name": "Sleepless",
        "partId": "eyes-sleepless",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-snowflakes": {
        "class": "beast",
        "name": "Snowflakes",
        "partId": "eyes-snowflakes",
        "specialGenes": "xmas",
        "type": "eyes"
    },
    "eyes-telescope": {
        "class": "aquatic",
        "name": "Telescope",
        "partId": "eyes-telescope",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-topaz": {
        "class": "reptile",
        "name": "Topaz",
        "partId": "eyes-topaz",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-tricky": {
        "class": "reptile",
        "name": "Tricky",
        "partId": "eyes-tricky",
        "specialGenes": "",
        "type": "eyes"
    },
    "eyes-yen": {
        "class": "aquatic",
        "name": "Yen",
        "partId": "eyes-yen",
        "specialGenes": "japan",
        "type": "eyes"
    },
    "eyes-zeal": {
        "class": "beast",
        "name": "Zeal",
        "partId": "eyes-zeal",
        "specialGenes": "",
        "type": "eyes"
    },
    "horn-5h04l-5t4r": {
        "class": "aquatic",
        "name": "5H04L-5T4R",
        "partId": "horn-5h04l-5t4r",
        "specialGenes": "bionic",
        "type": "horn"
    },
    "horn-anemone": {
        "class": "aquatic",
        "name": "Anemone (horn)",
        "partId": "horn-anemone",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-antenna": {
        "class": "bug",
        "name": "Antenna",
        "partId": "horn-antenna",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-arco": {
        "class": "beast",
        "name": "Arco",
        "partId": "horn-arco",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-babylonia": {
        "class": "aquatic",
        "name": "Babylonia",
        "partId": "horn-babylonia",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-bamboo-shoot": {
        "class": "plant",
        "name": "Bamboo Shoot",
        "partId": "horn-bamboo-shoot",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-beech": {
        "class": "plant",
        "name": "Beech",
        "partId": "horn-beech",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-bumpy": {
        "class": "reptile",
        "name": "Bumpy",
        "partId": "horn-bumpy",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-cactus": {
        "class": "plant",
        "name": "Cactus",
        "partId": "horn-cactus",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-candy-babylonia": {
        "class": "aquatic",
        "name": "Candy Babylonia",
        "partId": "horn-candy-babylonia",
        "specialGenes": "mystic",
        "type": "horn"
    },
    "horn-caterpillars": {
        "class": "bug",
        "name": "Caterpillars",
        "partId": "horn-caterpillars",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-cerastes": {
        "class": "reptile",
        "name": "Cerastes",
        "partId": "horn-cerastes",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-clamshell": {
        "class": "aquatic",
        "name": "Clamshell",
        "partId": "horn-clamshell",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-cuckoo": {
        "class": "bird",
        "name": "Cuckoo",
        "partId": "horn-cuckoo",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-dual-blade": {
        "class": "beast",
        "name": "Dual Blade",
        "partId": "horn-dual-blade",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-eggshell": {
        "class": "bird",
        "name": "Eggshell",
        "partId": "horn-eggshell",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-feather-spear": {
        "class": "bird",
        "name": "Feather Spear",
        "partId": "horn-feather-spear",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-golden-bamboo-shoot": {
        "class": "plant",
        "name": "Golden Bamboo Shoot",
        "partId": "horn-golden-bamboo-shoot",
        "specialGenes": "mystic",
        "type": "horn"
    },
    "horn-golden-shell": {
        "class": "bird",
        "name": "Golden Shell",
        "partId": "horn-golden-shell",
        "specialGenes": "mystic",
        "type": "horn"
    },
    "horn-imp": {
        "class": "beast",
        "name": "Imp",
        "partId": "horn-imp",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-incisor": {
        "class": "reptile",
        "name": "Incisor",
        "partId": "horn-incisor",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-kendama": {
        "class": "beast",
        "name": "Kendama",
        "partId": "horn-kendama",
        "specialGenes": "japan",
        "type": "horn"
    },
    "horn-kestrel": {
        "class": "bird",
        "name": "Kestrel",
        "partId": "horn-kestrel",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-lagging": {
        "class": "bug",
        "name": "Lagging",
        "partId": "horn-lagging",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-laggingggggg": {
        "class": "bug",
        "name": "Laggingggggg",
        "partId": "horn-laggingggggg",
        "specialGenes": "mystic",
        "type": "horn"
    },
    "horn-leaf-bug": {
        "class": "bug",
        "name": "Leaf Bug",
        "partId": "horn-leaf-bug",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-little-branch": {
        "class": "beast",
        "name": "Little Branch",
        "partId": "horn-little-branch",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-merry": {
        "class": "beast",
        "name": "Merry",
        "partId": "horn-merry",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-oranda": {
        "class": "aquatic",
        "name": "Oranda",
        "partId": "horn-oranda",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-p4r451t3": {
        "class": "bug",
        "name": "P4R451T3",
        "partId": "horn-p4r451t3",
        "specialGenes": "bionic",
        "type": "horn"
    },
    "horn-parasite": {
        "class": "bug",
        "name": "Parasite",
        "partId": "horn-parasite",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-pinku-unko": {
        "class": "reptile",
        "name": "Pinku Unko",
        "partId": "horn-pinku-unko",
        "specialGenes": "mystic",
        "type": "horn"
    },
    "horn-pliers": {
        "class": "bug",
        "name": "Pliers",
        "partId": "horn-pliers",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-pocky": {
        "class": "beast",
        "name": "Pocky",
        "partId": "horn-pocky",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-rose-bud": {
        "class": "plant",
        "name": "Rose Bud",
        "partId": "horn-rose-bud",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-scaly-spear": {
        "class": "reptile",
        "name": "Scaly Spear",
        "partId": "horn-scaly-spear",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-scaly-spoon": {
        "class": "reptile",
        "name": "Scaly Spoon",
        "partId": "horn-scaly-spoon",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-shoal-star": {
        "class": "aquatic",
        "name": "Shoal Star",
        "partId": "horn-shoal-star",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-spruce-spear": {
        "class": "bird",
        "name": "Spruce Spear",
        "partId": "horn-spruce-spear",
        "specialGenes": "xmas",
        "type": "horn"
    },
    "horn-strawberry-shortcake": {
        "class": "plant",
        "name": "Strawberry Shortcake",
        "partId": "horn-strawberry-shortcake",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-teal-shell": {
        "class": "aquatic",
        "name": "Teal Shell",
        "partId": "horn-teal-shell",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-trump": {
        "class": "bird",
        "name": "Trump",
        "partId": "horn-trump",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-umaibo": {
        "class": "beast",
        "name": "Umaibo",
        "partId": "horn-umaibo",
        "specialGenes": "japan",
        "type": "horn"
    },
    "horn-unko": {
        "class": "reptile",
        "name": "Unko",
        "partId": "horn-unko",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-watermelon": {
        "class": "plant",
        "name": "Watermelon",
        "partId": "horn-watermelon",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-wing-horn": {
        "class": "bird",
        "name": "Wing Horn",
        "partId": "horn-wing-horn",
        "specialGenes": "",
        "type": "horn"
    },
    "horn-winter-branch": {
        "class": "beast",
        "name": "Winter Branch",
        "partId": "horn-winter-branch",
        "specialGenes": "mystic",
        "type": "horn"
    },
    "horn-yorishiro": {
        "class": "plant",
        "name": "Yorishiro",
        "partId": "horn-yorishiro",
        "specialGenes": "japan",
        "type": "horn"
    },
    "mouth-axie-kiss": {
        "class": "beast",
        "name": "Axie Kiss",
        "partId": "mouth-axie-kiss",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-catfish": {
        "class": "aquatic",
        "name": "Catfish",
        "partId": "mouth-catfish",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-confident": {
        "class": "beast",
        "name": "Confident",
        "partId": "mouth-confident",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-cute-bunny": {
        "class": "bug",
        "name": "Cute Bunny",
        "partId": "mouth-cute-bunny",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-dango": {
        "class": "reptile",
        "name": "Dango",
        "partId": "mouth-dango",
        "specialGenes": "japan",
        "type": "mouth"
    },
    "mouth-doubletalk": {
        "class": "bird",
        "name": "Doubletalk",
        "partId": "mouth-doubletalk",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-feasting-mosquito": {
        "class": "bug",
        "name": "Feasting Mosquito",
        "partId": "mouth-feasting-mosquito",
        "specialGenes": "mystic",
        "type": "mouth"
    },
    "mouth-geisha": {
        "class": "aquatic",
        "name": "Geisha",
        "partId": "mouth-geisha",
        "specialGenes": "japan",
        "type": "mouth"
    },
    "mouth-goda": {
        "class": "beast",
        "name": "Goda",
        "partId": "mouth-goda",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-herbivore": {
        "class": "plant",
        "name": "Herbivore",
        "partId": "mouth-herbivore",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-humorless": {
        "class": "plant",
        "name": "Humorless",
        "partId": "mouth-humorless",
        "specialGenes": "mystic",
        "type": "mouth"
    },
    "mouth-hungry-bird": {
        "class": "bird",
        "name": "Hungry Bird",
        "partId": "mouth-hungry-bird",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-kawaii": {
        "class": "bug",
        "name": "Kawaii",
        "partId": "mouth-kawaii",
        "specialGenes": "japan",
        "type": "mouth"
    },
    "mouth-kotaro": {
        "class": "reptile",
        "name": "Kotaro",
        "partId": "mouth-kotaro",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-lam": {
        "class": "aquatic",
        "name": "Lam",
        "partId": "mouth-lam",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-lam-handsome": {
        "class": "aquatic",
        "name": "Lam Handsome",
        "partId": "mouth-lam-handsome",
        "specialGenes": "mystic",
        "type": "mouth"
    },
    "mouth-little-owl": {
        "class": "bird",
        "name": "Little Owl",
        "partId": "mouth-little-owl",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-mosquito": {
        "class": "bug",
        "name": "Mosquito",
        "partId": "mouth-mosquito",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-mr-doubletalk": {
        "class": "bird",
        "name": "Mr. Doubletalk",
        "partId": "mouth-mr-doubletalk",
        "specialGenes": "mystic",
        "type": "mouth"
    },
    "mouth-nut-cracker": {
        "class": "beast",
        "name": "Nut Cracker (mouth)",
        "partId": "mouth-nut-cracker",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-peace-maker": {
        "class": "bird",
        "name": "Peace Maker",
        "partId": "mouth-peace-maker",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-pincer": {
        "class": "bug",
        "name": "Pincer",
        "partId": "mouth-pincer",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-piranha": {
        "class": "aquatic",
        "name": "Piranha",
        "partId": "mouth-piranha",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-razor-bite": {
        "class": "reptile",
        "name": "Razor Bite",
        "partId": "mouth-razor-bite",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-risky-fish": {
        "class": "aquatic",
        "name": "Risky Fish",
        "partId": "mouth-risky-fish",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-rudolph": {
        "class": "plant",
        "name": "Rudolph",
        "partId": "mouth-rudolph",
        "specialGenes": "xmas",
        "type": "mouth"
    },
    "mouth-serious": {
        "class": "plant",
        "name": "Serious",
        "partId": "mouth-serious",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-silence-whisper": {
        "class": "plant",
        "name": "Silence Whisper",
        "partId": "mouth-silence-whisper",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-skull-cracker": {
        "class": "beast",
        "name": "Skull Cracker",
        "partId": "mouth-skull-cracker",
        "specialGenes": "mystic",
        "type": "mouth"
    },
    "mouth-square-teeth": {
        "class": "bug",
        "name": "Square Teeth",
        "partId": "mouth-square-teeth",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-tiny-turtle": {
        "class": "reptile",
        "name": "Tiny Turtle",
        "partId": "mouth-tiny-turtle",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-toothless-bite": {
        "class": "reptile",
        "name": "Toothless Bite",
        "partId": "mouth-toothless-bite",
        "specialGenes": "",
        "type": "mouth"
    },
    "mouth-venom-bite": {
        "class": "reptile",
        "name": "Venom Bite",
        "partId": "mouth-venom-bite",
        "specialGenes": "mystic",
        "type": "mouth"
    },
    "mouth-zigzag": {
        "class": "plant",
        "name": "Zigzag",
        "partId": "mouth-zigzag",
        "specialGenes": "",
        "type": "mouth"
    },
    "tail-ant": {
        "class": "bug",
        "name": "Ant",
        "partId": "tail-ant",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-carrot": {
        "class": "plant",
        "name": "Carrot",
        "partId": "tail-carrot",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-cattail": {
        "class": "plant",
        "name": "Cattail",
        "partId": "tail-cattail",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-cloud": {
        "class": "bird",
        "name": "Cloud",
        "partId": "tail-cloud",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-cottontail": {
        "class": "beast",
        "name": "Cottontail",
        "partId": "tail-cottontail",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-december-surprise": {
        "class": "reptile",
        "name": "December Surprise",
        "partId": "tail-december-surprise",
        "specialGenes": "xmas",
        "type": "tail"
    },
    "tail-escaped-gecko": {
        "class": "reptile",
        "name": "Escaped Gecko",
        "partId": "tail-escaped-gecko",
        "specialGenes": "mystic",
        "type": "tail"
    },
    "tail-feather-fan": {
        "class": "bird",
        "name": "Feather Fan",
        "partId": "tail-feather-fan",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-fire-ant": {
        "class": "bug",
        "name": "Fire Ant",
        "partId": "tail-fire-ant",
        "specialGenes": "mystic",
        "type": "tail"
    },
    "tail-fish-snack": {
        "class": "bug",
        "name": "Fish Snack",
        "partId": "tail-fish-snack",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-gerbil": {
        "class": "beast",
        "name": "Gerbil",
        "partId": "tail-gerbil",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-gila": {
        "class": "reptile",
        "name": "Gila",
        "partId": "tail-gila",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-granmas-fan": {
        "class": "bird",
        "name": "Granma's Fan",
        "partId": "tail-granmas-fan",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-grass-snake": {
        "class": "reptile",
        "name": "Grass Snake",
        "partId": "tail-grass-snake",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-gravel-ant": {
        "class": "bug",
        "name": "Gravel Ant",
        "partId": "tail-gravel-ant",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-hare": {
        "class": "beast",
        "name": "Hare",
        "partId": "tail-hare",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-hatsune": {
        "class": "plant",
        "name": "Hatsune",
        "partId": "tail-hatsune",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-hot-butt": {
        "class": "plant",
        "name": "Hot Butt",
        "partId": "tail-hot-butt",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-iguana": {
        "class": "reptile",
        "name": "Iguana",
        "partId": "tail-iguana",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-koi": {
        "class": "aquatic",
        "name": "Koi",
        "partId": "tail-koi",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-koinobori": {
        "class": "aquatic",
        "name": "Koinobori",
        "partId": "tail-koinobori",
        "specialGenes": "japan",
        "type": "tail"
    },
    "tail-kuro-koi": {
        "class": "aquatic",
        "name": "Kuro Koi",
        "partId": "tail-kuro-koi",
        "specialGenes": "mystic",
        "type": "tail"
    },
    "tail-maki": {
        "class": "bug",
        "name": "Maki",
        "partId": "tail-maki",
        "specialGenes": "japan",
        "type": "tail"
    },
    "tail-namek-carrot": {
        "class": "plant",
        "name": "Namek Carrot",
        "partId": "tail-namek-carrot",
        "specialGenes": "mystic",
        "type": "tail"
    },
    "tail-navaga": {
        "class": "aquatic",
        "name": "Navaga",
        "partId": "tail-navaga",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-nimo": {
        "class": "aquatic",
        "name": "Nimo",
        "partId": "tail-nimo",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-nut-cracker": {
        "class": "beast",
        "name": "Nut Cracker (tail)",
        "partId": "tail-nut-cracker",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-omatsuri": {
        "class": "bird",
        "name": "Omatsuri",
        "partId": "tail-omatsuri",
        "specialGenes": "japan",
        "type": "tail"
    },
    "tail-post-fight": {
        "class": "bird",
        "name": "Post Fight",
        "partId": "tail-post-fight",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-potato-leaf": {
        "class": "plant",
        "name": "Potato Leaf",
        "partId": "tail-potato-leaf",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-pupae": {
        "class": "bug",
        "name": "Pupae",
        "partId": "tail-pupae",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-ranchu": {
        "class": "aquatic",
        "name": "Ranchu",
        "partId": "tail-ranchu",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-rice": {
        "class": "beast",
        "name": "Rice",
        "partId": "tail-rice",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-sakura-cottontail": {
        "class": "beast",
        "name": "Sakura Cottontail",
        "partId": "tail-sakura-cottontail",
        "specialGenes": "mystic",
        "type": "tail"
    },
    "tail-shiba": {
        "class": "beast",
        "name": "Shiba",
        "partId": "tail-shiba",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-shrimp": {
        "class": "aquatic",
        "name": "Shrimp",
        "partId": "tail-shrimp",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-snake-jar": {
        "class": "reptile",
        "name": "Snake Jar",
        "partId": "tail-snake-jar",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-snowy-swallow": {
        "class": "bird",
        "name": "Snowy Swallow",
        "partId": "tail-snowy-swallow",
        "specialGenes": "mystic",
        "type": "tail"
    },
    "tail-swallow": {
        "class": "bird",
        "name": "Swallow",
        "partId": "tail-swallow",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-tadpole": {
        "class": "aquatic",
        "name": "Tadpole",
        "partId": "tail-tadpole",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-the-last-one": {
        "class": "bird",
        "name": "The Last One",
        "partId": "tail-the-last-one",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-thorny-caterpillar": {
        "class": "bug",
        "name": "Thorny Caterpillar",
        "partId": "tail-thorny-caterpillar",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-tiny-dino": {
        "class": "reptile",
        "name": "Tiny Dino",
        "partId": "tail-tiny-dino",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-twin-tail": {
        "class": "bug",
        "name": "Twin Tail",
        "partId": "tail-twin-tail",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-wall-gecko": {
        "class": "reptile",
        "name": "Wall Gecko",
        "partId": "tail-wall-gecko",
        "specialGenes": "",
        "type": "tail"
    },
    "tail-yam": {
        "class": "plant",
        "name": "Yam",
        "partId": "tail-yam",
        "specialGenes": "",
        "type": "tail"
    }
}
;
<template lang="pug">
#app
  keep-alive
    router-view
</template>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
html, body {
    height: 100%;
    margin: 0;
}

#app {
    color: #56b983;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

a.panel-block.is-active {
    border-left: 1px solid black;
    background-color: hsl(0, 0%, 86%);
}


// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>


<script>
export default {
    name: "app",
    data () {
        return {
            msg: 'Welcome to the VUE JS template'
        }
    },
    methods: {
        waitSecondsAsync: async function (sec) {
            return new Promise((resolve) => {
                setTimeout(resolve, sec*1000);
            });
        },
        test: async function () {
            await this.waitSecondsAsync(2);
            console.log("Finished waiting");
        }
    }
}
</script>

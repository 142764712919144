module.exports = {
  "allParts": ["eyes", "ears", "mouth", "horn", "back", "tail"],
  "mainParts": ["mouth", "horn", "back", "tail"],
  "stats": ["Health", "Speed", "Skill", "Morale"],
  "classes": ["Bird", "Plant", "Aquatic", "Reptile", "Beast", "Bug", "Dusk", "Mech", "Dawn"],
  "colours": {
    "bird": "rgb(255, 139, 189)",
    "plant": "rgb(108, 192, 0)",
    "aquatic": "rgb(0, 184, 206)",
    "reptile": "rgb(200, 138, 224)",
    "beast": "rgb(255, 184, 18)",
    "bug": "rgb(255, 83, 65)"
  }
}
;
import axios from 'axios';

const SERVER_URL = (process.env.NODE_ENV === 'production' ? `${process.env.LIVE_URL}` : `localhost:3000`);
const API_URL = (process.env.NODE_ENV === 'production' ? `https://${SERVER_URL}/api/` : `http://${SERVER_URL}/api/`);
const SOCKET_URL = (process.env.NODE_ENV === 'production' ? `wss://${SERVER_URL}` : `ws://${SERVER_URL}`);
const server = axios.create({
    baseURL: API_URL
});

let socket = null;

function wrapper (Vue) {
    // Check the userstore for existing auth tokens
    if (Vue.user.is_authenticated) {
        server.defaults.headers.common['x-access-token'] = Vue.user.token;
    }

    return {

        // Open a socket
        openSocket: async function () {
            socket = new WebSocket(SOCKET_URL);
            socket.onopen = function (e) {
                Vue.$log.debug(`Socket open`);
                // Vue.$log.debug(e);
            };

            socket.onmessage = function (e) {
                Vue.$log.debug(`Socket message recieved`);
                let parsedData = JSON.parse(e.data);
                // Vue.$log.debug(parsedData);
                if (parsedData.type == 'newListings') {
                    Vue.user.checkNewListings(parsedData.data);
                }
            };

            socket.onclose = function (e) {
                Vue.$log.debug(`Socket closed, clean = ${e.wasClean}`);

                // Try to reconnect after 2sec
                setTimeout(async function () {
                    await Vue.api.openSocket();

                }, 2000);
            }
        },

        closeSocket: async function () {
            socket.close();
        },

        // Auth
        login: async function (creds) {
            let res = await server.post('/auth/login', creds);
            Vue.$log.debug(`Successful login: token = ${res.data.token}`);

            let token = res.data.token;
            // Update user store
            Vue.user.token = token;
            // Push it to localstorage
            localStorage.setItem('user-jwt', token);
            // Update the axios default header
            server.defaults.headers.common['x-access-token'] = token;
            Vue.$log.debug("Successful login");
            Vue.$log.debug(Vue.user.info);
        },
        logout: async function () {
            // Remove it from localstorage
            Vue.user.token = null;
            localStorage.removeItem('user-jwt');
            delete server.defaults.headers.common['x-access-token'];

            // Close the websocket
            await Vue.api.closeSocket();

            // Clear the store
            await Vue.user.logout();

            Vue.$log.debug("Successful logout");
        },

        send_search: async function (criteria) {
            Vue.$log.debug("Sending search criteria");
            Vue.$log.debug(criteria);

            if (criteria.resultsCap == "") {
                criteria.resultsCap = null;
            }
            // Need to clone it
            let c = {
                search: JSON.parse(JSON.stringify(criteria))
            }
            let res = await server.post('search', c.search);
            c.results = res.data;
            Vue.user.addSearch(c);
        },

        delete_search: async function (criteria) {
            Vue.$log.debug("Deleting search criteria");
            Vue.$log.debug(criteria);

            // Clone it
            let s = JSON.parse(JSON.stringify(criteria));
            await server.post('delete', s);
            Vue.user.deleteSearch(s);

        },

        get_history: async function () {
            Vue.$log.debug("Getting search history");
            let res = await server.get('history');
            console.log(res);

            for (let i of res.data) {
                Vue.user.addSearch({search: i});
            }
        }
    }
}

export default wrapper;

<template lang="pug">
#mainblock
  b-collapse(v-model="searchOpen")
    .columns
      .column.is-half
        b-field(label="Class" label-position="on-border")
          b-select(placeholder="Any" v-model="criteria.class")
            option(v-for="c in $const.axie.classes" :value="c" :key="c") {{c}}

        b-field(label="Parts" label-position="on-border" grouped)
          b-input(list="partsList" v-model="partSearch" expanded)
          datalist#partsList
            option(v-for="p in primaryParts" :key="p.partId" :value="p.name")
          p.control
            b-button.is-success(:class="buttonClasses" :disabled="!isPartValid" @click="addPart('d')") D
          p.control
            b-button.is-info(:class="buttonClasses" :disabled="!isPartValid" @click="addPart('r1')") R1
          p.control
            b-button.is-warning(:class="buttonClasses" :disabled="!isPartValid" @click="addPart('r2')") R2

        b-field(label="Breeds" label-position="on-border")
          b-slider(v-model="criteria.breedCount" :min="0" :max="7" type="is-danger")

        b-field(label="Pureness" label-position="on-border")
          b-slider(v-model="criteria.pureness" :min="0" :max="6" type="is-warning")

        b-field(label="Purity" label-position="on-border")
          b-slider(v-model="criteria.purity" :min="0" :max="100" type="is-warning")

        b-field(label="Rsyn" label-position="on-border")
          b-slider(v-model="criteria.rsyn" :min="0" :max="100" type="is-warning")

        b-field(label="Speed" label-position="on-border")
          b-slider(v-model="criteria.speed" :min="27" :max="61")

        b-field(label="Search Name" label-position="on-border")
          b-input(placeholder="Dual blade breeders" v-model="criteria.searchName")

        b-field(label="Max results" label-position="on-border" grouped)
          b-input(placeholder="ALL" v-model="criteria.resultsCap" type="number" min="0")
          b-switch(v-model="criteria.checkNew") Check new listings

      .column.is-half
        p.subtitle.has-text-centered.is-underlined Selected parts

        b-field.is-capitalized(v-for="p in Object.keys(selectedParts).sort()" :label="p")
          b-field(v-for="g in Object.keys(selectedParts[p])" grouped)
            .control.is-normal
              p {{g}}:
            b-taglist
              b-tag(v-for="x in selectedParts[p][g]" closable type="is-dark" close-type="is-danger" @close="removePart(g, x)") {{x}}
    hr

  nav.level
    .level-item
      b-button(@click="toggleSearch") TOGGLE
    .level-item
      b-button(type="is-warning" @click="resetCriteria") CLEAR
    .level-item
      b-button(type="is-success" :disabled="searchPending" @click="submitSearch") SEARCH
      p.help.is-danger.has-text-centered {{searchError}}
    .level-item
      b-button(type="is-danger" @click="deleteSearch") DELETE
    .level-item
      b-button(@click="logout") LOGOUT


</template>

<style scoped>
option {
    text-transform: capitalize;
}
</style>

<script>
export default {
    name: "Search",
    data () {
        return {
            partSearch: null,
            searchOpen: false,
            criteria: {
                "class": null,
                speed: [27, 61],
                breedCount: [0, 7],
                pureness: 0,
                purity: 0,
                rsyn: 0,
                parts: {
                  d: [],
                  r1: [],
                  r2: [],
                },
                resultsCap: null,
                searchName: null,
                checkNew: true,
            },
            searchPending: false,
            searchError: null,
        }
    },
    watch: {
        "$user.activeItem": function (newVal) {
            if (newVal != null ) {
                this.criteria = JSON.parse(JSON.stringify(newVal.search));
                delete this.criteria.results;
            } else {
                this.resetCriteria();
            }
        }
    },
    methods: {
        addPart: function(part) {
            let p = this.primaryParts.find(a => a.name == this.partSearch);
            // Check if it's already in the set
            if (!this.criteria.parts[part].map(a => a.partId).includes(p.partId)) {
                this.criteria.parts[part].push(p);
            }

            this.partSearch = null;
        },
        removePart: function(g, partName) {
            this.criteria.parts[g] = this.criteria.parts[g].filter(a => a.name != partName);
        },
        submitSearch: async function () {
            this.searchPending = true;
            this.searchError = null;
            try {
                await this.$api.send_search(this.criteria);
            } catch (e) {
                console.log(e);
                this.searchError = `${e.message} - ${e.response.statusText}`;
            }

            this.searchPending = false;
        },
        deleteSearch: async function () {
            try {
                await this.$api.delete_search(this.criteria);
            } catch (e) {
                console.log(e);
                this.searchError = `${e.message} - ${e.response.statusText}`;
            }
        },
        resetCriteria: function () {
            this.criteria = JSON.parse(JSON.stringify({
                "class": null,
                speed: [27, 61],
                breedCount: [0, 7],
                pureness: 0,
                purity: 0,
                rsyn: 0,
                parts: {
                    d: [],
                    r1: [],
                    r2: [],
                },
                resultsCap: null,
                searchName: null,
                checkNew: true,
            }));
        },
        toggleSearch: function () {
            this.searchOpen = !this.searchOpen;
        },
        logout: async function () {
            await this.$api.logout();
            await this.$router.push('/');
        }
    },
    computed: {
        primaryParts: function () {
            return Object.values(this.$const.parts)
                .filter(a => this.$const.axie.mainParts.includes(a.type) && a.specialGenes == "")
                .sort((a, b) => a.name < b.name ? -1 : 1);
        },
        isPartValid: function () {
            return this.primaryParts.map(a => a.name).includes(this.partSearch);
        },
        buttonClasses: function () {
            return {
                'is-active': this.isPartValid,
            }
        },
        selectedParts: function () {
            let d = {};
            for (let g of Object.keys(this.criteria.parts)) {
                for (let p of this.criteria.parts[g]) {
                    // Check/create type (horn, mouth etc)
                    if (d[p.type] == null) {
                        d[p.type] = {};
                    }
                    // Check/create gene (d, r1, r2)
                    if (d[p.type][g] == null) {
                        d[p.type][g] = [];
                    }
                    // Actually add it
                    d[p.type][g].push(p.name);
                }
            }
            return d;
        },

    }

}
</script>


<template lang="pug">
#mainblock
  .container
    nav.panel.is-info
      p.panel-heading Recent Searches
      a.panel-block(v-for="(p, i) in $user.searchHistory" @click="selectSearch(i)" :class="activeStyle(i)") {{listNameString(p)}}

</template>

<script>
export default {
    name: "List",
    data () {
        return {
            ip_address: "N/A",
            server_response: "N/A"
        }
    },
    methods: {
        listNameString: function (p) {
            let s = "";
            if (p.search.searchName != null && p.search.searchName != "") {
                s += p.search.searchName;
            } else {
                let f = p.search.parts.d.map(a => a.name).join(', ');
                s += `${p.search.class == null ? 'Any' : p.search.class} - ${f}`;
            }

            if (p.results == null) {
                s += " (no res)";
            } else {
                s += ` (${p.results.axies.length})`;
            }
            return s;
        },
        selectSearch: function (i) {
            this.$user.listIndex = i;
        },
        activeStyle: function (i) {
            return {
                'is-active': i == this.$user.listIndex ? true : false
            }
        }
    },
    computed: {
        filteredParts: function () {
            return Object.keys(this.$const.parts).filter(a => a.startsWith('back'));
        }
    }
}
</script>

<style scoped>

</style>

<template lang="pug">
#mainblock
  .container
    .box
      Search
    .columns
      .column.is-one-third
        List
      .column.is-one-third
        .box
          Results
      .column.is-one-third
        .box
          RecentListings




</template>

<script>
import List from "./List";
import Results from "./Results";
import Search from "./Search";
import RecentListings from "./RecentListings";

export default {
    name: "Index",
    data () {
        return {
            ip_address: "N/A",
            server_response: "N/A"
        }
    },
    components: {
        List,
        Results,
        Search,
        RecentListings
    },
    activated: async function () {
        this.$log.debug("User is authenticated, getting history and opening websocket");
        await this.$api.get_history();
        await this.$api.openSocket();
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
